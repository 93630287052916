<template>
  <div class="simulation">
    <!--Header-->
    <div class="d-flex align-center">
      <Nav
        class="d-flex flex-grow-1 justify-space-around"
        :tabs="tabs"
        :currentTab="currentTab"
        @tab-selected="selectTab"
      />
      <div class="d-flex align-center">
        <div v-if="isExpert">
          <confirm
            textBtn
            v-if="!isTimeStarted"
            text="Вы действительно хотите запустить время?"
            @confirm="startWorkTime"
          >
            Запустить время
          </confirm>
          <confirm
            textBtn
            v-else
            text="Вы действительно хотите завершить оценку?"
            @confirm="finishSession"
          >
            Завершить сессию
          </confirm>
        </div>
        <p class="primary--text pl-3 pl-2" style="font-size: 28px; width: 85px">
          {{ timer }}
        </p>
      </div>
    </div>

    <!--Main-->
    <div class="d-flex flex-grow-1 mt-5">
      <component
        :is="getComponent"
        class="d-flex flex-grow-1"
        :session="simulation"
      />
      <iframe
        v-if="simulation.isEnabledEmbeddedVideo"
        style="min-width: 410px"
        height="100%"
        width="410px"
        frameborder="0"
        allowfullscreen
        allow="camera; microphone; display-capture"
        :src="videoCallLink + '&displayName=' + fullName"
      />
    </div>

    <preloader v-if="loading" class="proloader" />
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  inject,
  watch,
  onMounted,
} from "@vue/composition-api";

import { useTabs } from "@/use/helpers";

import Nav from "@/components/shared/assets/Nav";

import Email from "./simulation/Email";
import Calendar from "./simulation/Calendar";
import Documents from "./Preparing"; // TODO: Rename
import Notes from "./simulation/Notes";
import Tasks from "./simulation/Tasks";

export default {
  setup(_, { root, emit }) {
    const store = root.$store;

    const tabs = computed(() => {
      const tmpTabs = [
        {
          name: "email",
          label: "Почта",
        },
        {
          name: "calendar",
          label: "Календарь",
        },
        {
          name: "documents",
          label:
            store.getters.getCurrentRole === "participant"
              ? "Мои документы"
              : "Документы",
        },
        {
          name: "notes",
          label: "Заметки",
        },
      ];
      if (store.getters.getCurrentRole !== "participant")
        tmpTabs.push({
          name: "tasks",
          label: "Задания",
        });
      tmpTabs.push({
        name: "exit",
        label: "Выход",
      });

      return tmpTabs;
    });

    const { currentTab, selectTab } = useTabs("email");

    const isExpert = computed(() => store.getters.getCurrentRole === "expert");

    const isTimeStarted = computed(
      () => !!store.getters.getSimulation.workStartTs
    );
    const startWorkTime = () => store.dispatch("startWorkTime");
    const finishSession = () => store.dispatch("finishSession");

    const timer = computed(() => {
      let remaining = store.getters.getSimulation.remainingSimulationSeconds;
      const endTimeArr = store.getters.getSimulation.simulationEndTs
        ?.split("T")[1]
        .split(":") || [24, 0];
      if (store.getters.getSimulation.status === "FINISHED" || remaining <= 0)
        return `${endTimeArr[0]}:${endTimeArr[1]}`;

      let hours =
        Number.parseInt(endTimeArr[0], 10) - Math.floor(remaining / 3600);
      remaining -= 3600 * Math.floor(remaining / 3600);
      let minutes = parseInt(endTimeArr[1], 10) - Math.ceil(remaining / 60);
      if (minutes < 0) {
        hours -= 1;
        minutes = 60 + minutes;
      }

      return `${hours}:${minutes > 9 ? minutes : "0" + minutes}`;
    });

    watch(
      () => store.getters.getSimulation.status,
      val => {
        if (val === "IN_PROGRESS") store.dispatch("startSyncSimulation");
        if (val === "FINISHED") {
          store.commit("clearSimulation");
          if (store.getters.getCurrentRole === "participant")
            emit("tab-selected", "Main");
        }
      }
    );

    const loading = inject("loading");

    const videoCallLink = store.getters.getSimulation.videoCallLink;

    const fullName = store.getters.getFullName;

    const isHeaderHidden = inject("isHeaderHidden");
    isHeaderHidden.value = true;

    const simulation = computed(() => store.getters.getSimulation);

    onBeforeMount(() => {
      store.dispatch("startSyncSimulation");
      loading.value = false;
    });

    onBeforeUnmount(() => {
      store.commit("clearIntervals");
      isHeaderHidden.value = false;
      store.commit("clearReports");
      if (store.getters.getCurrentRole !== "participant")
        store.dispatch("fetchReports");
    });

    const getComponent = computed(() => {
      switch (currentTab.value) {
        case "email":
          return Email;
        case "calendar":
          return Calendar;
        case "documents":
          return Documents;
        case "notes":
          return Notes;
        case "tasks":
          return Tasks;
        case "exit": {
          localStorage.setItem("lastTab", "Sessions");
          store.commit("clearSimulation");
          if (store.getters.getCurrentRole === "participant")
            emit("tab-selected", "Main");
          else emit("tab-selected", "Sessions");
        }
      }
    });

    onMounted(() => {
      localStorage.setItem("lastTab", "Simulation");
      localStorage.setItem("sessionId", store.getters.getSimulation.sessionId);
    });

    return {
      tabs,
      currentTab,
      selectTab,
      isExpert,
      isTimeStarted,
      startWorkTime,
      finishSession,
      timer,
      loading,
      videoCallLink,
      fullName,
      simulation,
      getComponent,
    };
  },
  components: {
    Nav,
    Email,
    Calendar,
    Documents,
    Notes,
    Tasks,
  },
};
</script>

<style lang="scss" scoped>
.simulation {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;

  display: flex;
  flex-flow: column nowrap;
  padding: 0;
}
.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
