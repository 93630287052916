var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{style:({
      height: '68px',
    }),attrs:{"justify":"end","align":"center"}},[_c('v-col',{staticClass:"d-flex justify-end"},[_c('div',[_c('span',{staticClass:"filter-btn",on:{"click":_vm.switchFilterToggle}},[_vm._v(" "+_vm._s(_vm.filterToggle ? "Скрыть фильтры" : "Показать фильтры")+" ")])])])],1),_c('v-row',[_c('v-container',[(_vm.filterToggle)?_c('DataFilter'):_vm._e(),_c('infinity-collection',{on:{"scrolled":_vm.getSessions}},[_c('v-data-table',{staticClass:"elevation-6",staticStyle:{"width":"100%"},attrs:{"mobile-breakpoint":null,"fixed-header":"","loading":_vm.loading,"show-expand":"","item-key":"id","headers":_vm.headers,"items":_vm.content,"items-per-page":_vm.content.length,"hide-default-footer":"","height":"450px","expanded":_vm.expanded,"custom-sort":_vm.customSort,"sort-by":['assessmentSessionDate']},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('ExpandedRow',{attrs:{"headers":headers,"item":item}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(['IN_PROGRESS', 'PENDING'].includes(item.status))?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectSession(item.id)}}},[_c('v-icon',{attrs:{"color":"#767676"}},[_vm._v(" mdi-plus-box-outline ")])],1):_vm._e()]}}])})],1)],1)],1),(_vm.loading)?_c('preloader',{staticClass:"preloader"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }