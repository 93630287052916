<template>
  <v-container>
    <div
      class="flex-grow-1"
      style="
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        grid-auto-rows: min-content;
      "
    >
      <div
        v-for="(doc, index) in session.documents"
        :key="doc.id"
        :style="{
          'grid-coliumn': (index % 2) + 1,
        }"
      >
        <a :href="validLink(doc.link)" target="_blank">
          <v-card elevation="0" class="card-material">
            <p class="text-center text--primary pa-0 card-material_title">
              {{ doc.name }}
            </p>
          </v-card>
        </a>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["session", "isViewMode"],
  setup() {
    const validLink = link => {
      const testedLink = /^https?:\/\//.test(link);
      return testedLink ? link : `http://${link}`;
    };

    return {
      validLink,
    };
  },
};
</script>
