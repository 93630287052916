<template>
  <v-card elevation="0">
    <v-card-text>
      <div class="d-flex">
        <v-list-item-subtitle
          class="pt-2"
          style="max-width: 62px; min-width: 62px"
          >Кому:
        </v-list-item-subtitle>
        <div class="flex-grow-1">
          <v-select
            v-model="message.from"
            :items="session.recipients"
            dense
            outlined
            disabled
          >
          </v-select>
        </div>
      </div>
      <div class="d-flex my-n4">
        <v-list-item-subtitle
          class="pt-2"
          style="max-width: 62px; min-width: 62px"
          >Копия:
        </v-list-item-subtitle>
        <div class="flex-grow-1">
          <v-select
            class="chip-wrap"
            v-model="message.draft.copy"
            :items="session.recipients.filter(el => el != message.from)"
            multiple
            chips
            dense
            outlined
          >
            <template v-slot:selection="{ item }">
              <v-list-item-content>
                <p>{{ item }}</p>
              </v-list-item-content>
            </template>
          </v-select>
        </div>
      </div>
      <div
        class="py-5 d-flex flex-column brd-a"
        style="font-size: 14px;
          height: 290px
          border-radius: 6px;"
      >
        <v-textarea
          v-model="editedText"
          solo
          :no-resize="true"
          label="Введите текст ..."
          class="mt-n3 pb-1 px-1 flex-grow-1 textarea--solo"
        ></v-textarea>
      </div>

      <div class="d-flex justify-end py-5 mx-1 brd-b">
        <v-btn class="form-btn mr-5" @click="cancelModal = true"
          >Отменить</v-btn
        >
        <v-btn class="form-btn" @click="confirmModal = validate()"
          >Ответить</v-btn
        >
      </div>
    </v-card-text>
    <v-dialog v-model="confirmModal" max-width="320px" persistent>
      <v-card>
        <v-card-title class="justify-center text-center">
          <p class="form-header-text">
            Вы действительно хотите отправить сообщение?
          </p>
        </v-card-title>
        <v-card-actions class="justify-space-around pa-5">
          <v-btn
            width="130px"
            class="white--text primary"
            @click="confirmModal = false"
          >
            Отменить
          </v-btn>
          <v-btn width="130px" class="white--text primary" @click="send()">
            Подтвердить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelModal" max-width="320px">
      <v-card>
        <v-card-title class="justify-center text-center">
          <p class="form-header-text">
            Вы действительно хотите отменить ввод сообщения?
          </p>
        </v-card-title>
        <v-card-actions class="justify-space-around pa-5">
          <v-btn
            width="130px"
            class="white--text primary"
            @click="cancelModal = false"
          >
            Отменить
          </v-btn>
          <v-btn width="130px" class="white--text primary" @click="cancel">
            Подтвердить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import { useLineBreakReplacer } from "@/use/helpers";

export default {
  props: ["session", "message", "isViewMode"],
  setup(_, { root, emit }) {
    const store = root.$store;

    const { replacer } = useLineBreakReplacer();

    const editedText = ref(_.message.draft?.text || "");
    watch(
      () => editedText.value,
      val =>
        store.commit("updateDraft", {
          messageId: _.message.id,
          draft: {
            text: val,
          },
        })
    );

    const validate = () => !!editedText.value;

    const send = () =>
      store
        .dispatch("replyEmail", {
          emailId: _.message.id,
          simulationId: _.session.id,
          reply: JSON.parse(JSON.stringify(_.message.draft)),
        })
        .then(() => cancel());

    const confirmModal = ref(false);

    const cancelModal = ref(false);

    _.message.draft.isOpen = true;

    const cancel = () => {
      _.message.draft.isOpen = false;
      _.message.draft.copy.length = 0;
      _.message.draft.text = "";
      confirmModal.value = false;
      cancelModal.value = false;
      emit("close");
    };

    return {
      editedText,
      replacer,
      validate,
      send,
      confirmModal,
      cancelModal,
      cancel,
    };
  },
};
</script>
