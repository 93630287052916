<template>
  <div>
    <ExpansionList v-if="competences.length" :items="competences">
      <template v-slot:header="{ item }">
        <div class="d-flex justify-space-between">
          <span>{{ item.indexNumber }}. {{ item.name }}</span>
        </div>
      </template>
      <template v-slot:content="{ item }">
        <v-card
          v-for="(indicator, index) in item.indicators"
          :key="index"
          class="px-5 py-2"
          tile
          @click="selectedIndicator = indicator"
        >
          {{ item.indexNumber }}.{{ indicator.indexNumber }}
          {{ indicator.name }}
        </v-card>
      </template>
    </ExpansionList>
    <p v-else class="pa-3">Индикаторов нет</p>
    <v-card class="mt-4 pa-3" v-if="selectedIndicator">
      <h3 class="my-3">Оценка по шкале</h3>
      <div class="my-3" v-for="mark in marksDescription" :key="mark.id">
        <h3 style="display: inline">{{ mark.level }}</h3>
        - {{ mark.description }}
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import ExpansionList from "@/components/global/assets/ExpansionList";

export default {
  props: {
    competences: Array,
    generalEmailReply: String,
    id: String,
    name: String,
    notes: Array,
    task: Object,
  },
  setup() {
    const selectedIndicator = ref(null);

    const marksDescription = computed(() =>
      selectedIndicator.value.levelMarks.sort((a, b) => {
        if (!isNaN(Number.parseInt(a.level))) {
          if (!isNaN(Number.parseInt(b.level))) return a.level - b.level;
          else return 1;
        } else return -1;
      })
    );

    return {
      selectedIndicator,
      marksDescription,
    };
  },
  components: { ExpansionList },
};
</script>
