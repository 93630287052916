<template>
  <v-container
    id="emailPage"
    :fluid="true"
    v-if="session.id"
    style="height: 100%"
    class="pa-0"
  >
    <div class="d-flex flex-grow-1" style="height: 100%">
      <div class="pt-3 d-flex flex-column" style="width: 300px">
        <div class="d-flex justify-center">
          <v-btn
            :class="['mx-3 mb-3 tab', { 'tab--active': listTab == 'incoming' }]"
            @click="listTab = 'incoming'"
          >
            Входящие
          </v-btn>
          <v-btn
            :class="['mx-3 mb-3 tab', { 'tab--active': listTab == 'outgoing' }]"
            @click="listTab = 'outgoing'"
          >
            Отправленные
          </v-btn>
        </div>
        <v-list
          two-line
          style="position: relative"
          color="rgba(231, 233, 243, 0.3)"
          class="pa-0 flex-grow-1 brd-a"
        >
          <v-list-item-group
            mandatory
            v-model="selected"
            active-class="message--active"
            style="
              overflow-y: auto;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
            "
          >
            <template
              v-for="(item, index) in listTab === 'incoming'
                ? incomingEmails
                : outgoingEmails"
            >
              <div :key="listTab + item.id + index" style="position: relative">
                <v-list-item
                  :class="[
                    'py-2',
                    'message',
                    {
                      'message--replied':
                        listTab == 'outgoing' || messageHasReply(item),
                    },
                  ]"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <div
                        v-if="item.documents && item.documents.length"
                        class="clip"
                        :style="{
                          backgroundImage: !active
                            ? `url(${require('@/assets/img/clip.svg')})`
                            : `url(${require('@/assets/img/clip-active.svg')})`,
                        }"
                      />

                      <div
                        class="d-flex justify-space-between align-center"
                        :style="{
                          maxWidth: '100% ',
                        }"
                      >
                        <v-list-item-title
                          style="font-weight: 500; font-size: 13px"
                          v-text="item.from"
                        ></v-list-item-title>
                        <p
                          class="pl-3 hint"
                          :style="{
                            fontWeight: '300',
                          }"
                        >
                          {{
                            listTab == "incoming"
                              ? messageReceiptDate(item.receiptTime)
                              : "Сегодня"
                          }}
                        </p>
                      </div>

                      <v-list-item-subtitle
                        class="text--primary my-2 pr-10"
                        v-text="item.title"
                      ></v-list-item-subtitle>

                      <v-list-item-action-text
                        style="font-size: 12px; line-height: 17px"
                      >
                        {{ item.text.slice(0, 83) }}
                        <span v-if="item.text.length > 83">...</span>
                      </v-list-item-action-text>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <Bookmark
                  :isViewMode="isViewMode"
                  :email="item"
                  @toggle-bookmark="switchImportant(item.id)"
                  style="position: absolute; right: 20px; top: 0"
                />
              </div>

              <v-divider
                v-if="index < incomingEmails.length - 1"
                :key="~index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
      <div
        v-if="listTab == 'outgoing' && Object.keys(selectedMessage).length"
        class="flex-grow-1 d-flex flex-column"
        style="position: relative"
      >
        <div class="px-4 pt-4">
          <v-card elevation="0">
            <v-card-text class="pa-0">
              <p>Тема письма:</p>
            </v-card-text>
          </v-card>
          <div class="d-flex justify-space-between mt-1 pb-4 brd-b">
            <v-list-item-title
              style="font-weight: 500; font-size: 16px; white-space: normal"
              class="text--primary"
              v-text="selectedMessage.title"
              :title="selectedMessage.title"
            ></v-list-item-title>
          </div>
        </div>
        <div class="flex-grow-1" style="position: relative">
          <div
            style="
              overflow-y: auto;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
            "
          >
            <div>
              <ReplyMessage
                :isViewMode="isViewMode"
                :session="session"
                :message="selectedMessage"
              />
              <Email
                :isViewMode="isViewMode"
                :session="session"
                :message="referencedMessage"
                :isReplyOpen="isReplyOpen"
                type="incoming"
                @reply="isReplyOpen = true"
              />
            </div>
          </div>
        </div>
        <Bookmark
          :isViewMode="isViewMode"
          :toggle="selectedMessage.important"
          @toggle-bookmark="
            selectedMessage.important = !selectedMessage.important
          "
          style="position: absolute; right: 20px; top: 0"
        />
      </div>
      <div
        v-else-if="listTab == 'incoming' && Object.keys(selectedMessage).length"
        class="flex-grow-1 d-flex flex-column"
        style="position: relative"
      >
        <div class="px-4 pt-4" v-if="isReplyOpen">
          <v-card elevation="0">
            <v-card-text class="pa-0">
              <p>Тема письма:</p>
            </v-card-text>
          </v-card>
          <div class="d-flex justify-space-between mt-1 pb-4 brd-b">
            <v-list-item-title
              style="font-weight: 500; font-size: 16px; white-space: normal"
              class="text--primary"
              v-text="'RE: ' + selectedMessage.title"
              :title="selectedMessage.title"
            ></v-list-item-title>
          </div>
        </div>
        <div class="flex-grow-1" style="position: relative">
          <div
            ref="messageWrap"
            style="
              overflow-y: auto;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
            "
          >
            <div>
              <ReplyForm
                v-if="
                  !isViewMode &&
                  !messageHasReply(selectedMessage) &&
                  isReplyOpen
                "
                :key="replyKey"
                :isViewMode="isViewMode"
                :session="session"
                :message="selectedMessage"
                @close="isReplyOpen = false"
              />
              <Email
                :isViewMode="isViewMode"
                :session="session"
                :message="selectedMessage"
                :isReplyOpen="isReplyOpen"
                type="incoming"
                @reply="openReply"
              />
            </div>
          </div>
        </div>
        <Bookmark
          :isViewMode="isViewMode"
          :toggle="selectedMessage.important"
          @toggle-bookmark="switchImportant(selectedMessage.id)"
          style="position: absolute; right: 20px; top: 0"
        />
        <Bookmark :toggle="true" class="hidden" />
      </div>
    </div>
  </v-container>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";
import { useLineBreakReplacer } from "@/use/helpers";
import Bookmark from "./assets/Bookmark";
import Email from "@/components/shared/assets/Email";
import ReplyForm from "./assets/ReplyForm";
import ReplyMessage from "./assets/ReplyMessage";

export default {
  setup(_, { refs, root }) {
    const store = root.$store;

    const session = computed(() => store.getters.getSimulation);

    const replyKey = ref(0);

    const isViewMode = computed(
      () => store.getters.getCurrentRole !== "participant"
    );

    const { replacer } = useLineBreakReplacer();

    const listTab = ref("incoming");
    watch(listTab, () => (selected.value = -1));

    const incomingEmails = computed(() => session.value.incomingEmails || []);
    const outgoingEmails = computed(
      () =>
        Object.entries(session.value.sentEmails).map(([key, value]) => {
          value.ref = key;
          return value;
        }) || []
    );

    const selected = ref(0);
    watch(
      () => selected.value,
      () => (replyKey.value += 1)
    );

    const selectedMessage = computed(() => {
      switch (listTab.value) {
        case "incoming":
          return incomingEmails.value[selected.value || 0] || {};
        case "outgoing":
          return outgoingEmails.value[selected.value || 0] || {};
        default:
          return incomingEmails.value[selected.value || 0] || {};
      }
    });
    const referencedMessage = computed(() => {
      if (listTab.value === "outgoing")
        return incomingEmails.value.find(
          el => el.id === selectedMessage.value.ref
        );
      else return {};
    });

    const switchImportant = id => {
      let email;
      if (listTab.value === "incoming")
        email = incomingEmails.value.find(el => el.id == id);
      else email = outgoingEmails.value.find(el => el.id == id);

      store.dispatch("toggleImportant", {
        emailId: listTab.value === "outgoing" ? email.ref : id,
        isImportant: !email.important,
        type: listTab.value === "outgoing" ? "sent" : "incoming",
      });
    };

    const isReplyOpen = ref(false);
    const openReply = () => {
      isReplyOpen.value = true;

      if (refs.messageWrap) {
        refs.messageWrap.scrollTop = 0;
      }
    };

    const messageHasReply = message =>
      outgoingEmails.value.find(el => el.ref === message.id);

    const messageReceiptDate = date => {
      let simulationDate = new Date(session.value.simulationTs.split("T")[0]);
      date = new Date(date.split("T")[0]);

      const formattedDate = date.toLocaleString("ru-RU", {
        month: "2-digit",
        day: "2-digit",
      });

      return Number(simulationDate) == Number(date)
        ? "Сегодня"
        : (Number(simulationDate) - Number(date)) / 3600000 == 24
        ? "Вчера"
        : formattedDate;
    };

    return {
      session,
      replyKey,
      isViewMode,
      replacer,
      listTab,
      incomingEmails,
      outgoingEmails,
      selected,
      selectedMessage,
      switchImportant,
      referencedMessage,
      isReplyOpen,
      openReply,
      messageHasReply,
      messageReceiptDate,
    };
  },
  components: {
    Bookmark,
    Email,
    ReplyForm,
    ReplyMessage,
  },
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none !important;
  user-select: none !important;
}
#emailPage {
  .message {
    background-color: #fff !important;
    &::before {
      background-color: #fff !important;
    }
    &--replied {
      background-color: #e0e0e0 !important;
      &::before {
        background-color: #e0e0e0 !important;
      }
    }
    &--active {
      background-color: $mainColor !important;
      * {
        color: #fff !important;
      }
      &::before {
        background-color: $mainColor !important;
      }
    }
  }
  .tab {
    padding: 5px 10px;
    border-radius: 9px;
    background: transparent !important;
    box-shadow: none;
    cursor: pointer;
    &--active {
      background: $mainColor !important;
      color: #fff;
    }
  }

  .clip {
    width: 7px;
    height: 15px;
    position: absolute;
    left: 4px;
    top: 20px;
  }
}
</style>
