<template>
  <div class="d-flex flex-grow-1 flex-column text-left pa-3">
    <p>{{ task.description }}</p>
    <h3 class="mt-3">Письма:</h3>
    <ExpansionList v-if="emails.length" :items="emails" :key="emailsKey">
      <template v-slot:header="_">
        <div class="d-flex justify-space-between">
          <span style="white-space: break-spaces">{{ _.item.title }}</span>
          <span>
            <v-icon
              v-if="_.item.id == task.generalEmailId"
              :style="{
                color: '#fd3018 !important',
              }"
              small
            >
              mdi-exclamation-thick
            </v-icon>
            <v-icon
              :style="{
                color: _.item.important
                  ? '#fd3018 !important'
                  : '#a9a9aa !important',
              }"
              small
            >
              mdi-bookmark
            </v-icon>
          </span>
        </div>
      </template>
      <template v-slot:content="_">
        <div>
          <Email :isViewMode="true" :message="_.item" type="incoming" />
        </div>
      </template>
    </ExpansionList>
    <v-card v-else class="pa-3">Писем нет</v-card>
    <h3 class="mt-3">Встречи:</h3>
    <ExpansionList v-if="task.meetings.length" :items="task.meetings">
      <template v-slot:header="_">
        <div>
          <span style="white-space: break-spaces">{{ _.item.title }}</span>
        </div>
      </template>
      <template v-slot:content="_">
        <Meeting :event="_.item" />
      </template>
    </ExpansionList>
    <v-card v-else class="pa-3">Встреч нет</v-card>
  </div>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";
import ExpansionList from "@/components/global/assets/ExpansionList";
import Email from "@/components/shared/assets/Email";
import Meeting from "@/components/shared/simulation/assets/Meeting";

export default {
  props: {
    competences: Array,
    generalEmailReply: String,
    id: String,
    name: String,
    notes: Array,
    task: Object,
  },
  setup(_) {
    const getDateString = (date, startTime, endTime) => {
      return `${date.slice(0, 10)}  ${startTime} - ${endTime}`;
    };

    const emails = computed(() =>
      _.task.emails.map(email => ({ ...email, draft: {} }))
    );

    const emailsKey = ref(0);

    watch(
      () => _.task,
      () => (emailsKey.value += 1),
      { deep: true }
    );

    return {
      getDateString,
      emails,
      emailsKey,
    };
  },
  components: {
    ExpansionList,
    Email,
    Meeting,
  },
};
</script>
