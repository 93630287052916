<template>
  <v-container
    id="tastsPage"
    fluid
    class="h-100 pa-2"
    style="max-height: calc(100vh - 90px)"
  >
    <v-row class="d-flex h-100">
      <v-col style="max-width: 35%">
        <v-card>
          <v-list class="py-0">
            <v-list-item-group
              color="primary"
              v-model="selectedIndex"
              mandatory
            >
              <v-list-item v-for="tab in tasks" :key="tab.name" class="py-2">
                <v-list-item-content>
                  <v-list-item-title style="white-space: break-spaces">
                    {{ tab.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column" style="max-height: 100%">
        <Nav
          class="mb-3"
          :tabs="tabs"
          :currentTab="currentTab"
          @tab-selected="selectTab"
        />
        <component
          :is="currentTab"
          v-bind="tasks[selectedIndex]"
          style="max-height: calc(100vh - 100px); overflow-y: auto"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import { useTabs } from "@/use/helpers";
import Nav from "@/components/shared/assets/Nav";
import Content from "./assets/tasks/Content";
import Instructions from "./assets/tasks/Instructions";
import Indicators from "./assets/tasks/Indicators";

export default {
  props: ["session"],
  setup(_) {
    const tasks = ref(_.session.tasks);
    const selectedIndex = ref(0);
    watch(
      () => selectedIndex.value,
      () => (currentTab.value = tabs[0].name)
    );

    const { currentTab, selectTab } = useTabs();
    const tabs = [
      {
        name: "Content",
        label: "Содержание",
      },
      {
        name: "Instructions",
        label: "Инструкция",
      },
      {
        name: "Indicators",
        label: "Индикаторы",
      },
    ];
    currentTab.value = tabs[0].name;

    return {
      tasks,
      selectedIndex,
      tabs,
      currentTab,
      selectTab,
    };
  },
  components: {
    Nav,
    Content,
    Instructions,
    Indicators,
  },
};
</script>
