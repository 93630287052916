<template>
  <component :is="sessionSelected ? 'Simulation' : 'SessionList'" />
</template>

<script>
import { computed, onMounted } from "@vue/composition-api";

import Simulation from "./Simulation";
import SessionList from "./sessions/SessionList";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const sessionSelected = computed(() => !!store.getters.getSimulation.id);

    onMounted(() => localStorage.setItem("lastTab", "Sessions"));

    return {
      sessionSelected,
    };
  },
  components: {
    SessionList,
    Simulation,
  },
};
</script>
