<template>
  <td :colspan="headers.length" style="cursor: initial">
    <div class="pa-5">
      <div class="d-flex">
        <div
          class="mr-5"
          :style="{
            position: 'relative',
          }"
        >
          <div
            class="mb-3"
            :style="{
              width: '240px',
            }"
          >
            <p class="subtitle mb-1 primary--text">Эксперты по оценке:</p>
            <v-card
              v-if="item.expertsList.length"
              class="mb-1"
              elevation="3"
              :style="{
                maxHeight: '164px',
                overflowY: 'auto',
                position: 'relative',
              }"
            >
              <v-list dense class="py-0">
                <v-list-item
                  class="brd-b"
                  v-for="(item, idx) in item.expertsList"
                  :key="idx"
                >
                  <v-list-item-content>
                    <div
                      style="max-width: 100%"
                      class="d-flex justify-space-between align-center"
                    >
                      <p
                        :style="{
                          maxWidth: '100%',
                          lineHeight: '20px',
                        }"
                      >
                        {{ item.fullName }}
                      </p>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-icon
                :style="{
                  color: '#fd3018 !important',
                  position: 'absolute',
                  top: '8px',
                  right: '10px',
                }"
                >mdi-bookmark</v-icon
              >
            </v-card>
          </div>
          <v-hover
            v-slot="{ hover }"
            :style="{
              position: 'absolute',
              top: '0',
              right: '0',
            }"
          >
            <div>
              <v-icon color="#767676" small class="text-center"
                >mdi-help-circle
              </v-icon>
              <div style="position: relative">
                <div
                  v-if="hover"
                  class="elevation-3 pa-3 white"
                  style="
                    position: absolute;
                    z-index: 100;
                    overflow-y: auto;
                    max-height: 200px;
                    width: 275px;
                    right: 0;
                  "
                >
                  Эксперты по оценке проводят ассессмент-центр и составляют
                  отчет по итогам оценки. Красным флажком отмечен главный
                  эксперт по оценке, который отвечает за итоговую проверку и
                  публикацию отчета с результатами оценки
                </div>
              </div>
            </div>
          </v-hover>
        </div>
        <list
          style="width: 240px"
          class="mr-5"
          :cases="{ title: 'Руководители' }"
          itemTitle="fullName"
          :data="item.leadersList"
          height="164px"
        >
        </list>
        <list
          style="width: 240px"
          class="mr-5"
          :cases="{ title: 'Наблюдатели' }"
          itemTitle="fullName"
          :data="item.observersList"
          height="164px"
        >
        </list>
        <list
          style="width: 240px"
          class="mr-5"
          :cases="{ title: 'Администратор' }"
          itemTitle="fullName"
          :data="[
            `${item.participantUser.invitedBy}<br>${item.createdByEmail}<br>${item.createdByPhone}`,
          ]"
          string-array
        >
        </list>
      </div>
      <div class="d-flex">
        <list
          :style="{
            width: '350px',
          }"
          :cases="{ title: 'Материалы для подготовки' }"
          itemTitle="name"
          links
          :data="item.preparationMaterials"
          height="247px"
        >
        </list>
      </div>
    </div>
  </td>
</template>

<script>
export default {
  props: ["headers", "item"],
};
</script>
