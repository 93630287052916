<template>
  <div class="d-flex flex-column flex-grow-1" style="max-height: 99%">
    <v-card
      class="pa-3 flex-grow-1"
      v-html="replacer(instructionsText)"
      style="overflow-y: auto"
    />
    <div v-if="attachments.length" class="my-3">
      <v-card
        class="pa-3 mb-1"
        v-for="(attachment, index) in attachments"
        :key="index"
      >
        <a :href="attachment.url" target="_blank">{{ attachment.name }}</a>
      </v-card>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import { useLineBreakReplacer } from "@/use/helpers";

export default {
  props: {
    competences: Array,
    generalEmailReply: String,
    id: String,
    name: String,
    notes: Array,
    task: Object,
  },
  setup(_) {
    const { replacer } = useLineBreakReplacer();

    const instructionsText = computed(
      () =>
        _.task.instructions.map(instr => instr.text).join("\n\n") ||
        "Нет инструкций"
    );

    const attachments = computed(
      () =>
        _.task.instructions
          .map(instr => ({
            name: instr.attachmentName,
            url: instr.attachmentLink,
          }))
          .filter(instr => !!instr.name) || []
    );

    return {
      replacer,
      instructionsText,
      attachments,
    };
  },
};
</script>
