var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticStyle:{"cursor":"initial"},attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5",style:({
          position: 'relative',
        })},[_c('div',{staticClass:"mb-3",style:({
            width: '240px',
          })},[_c('p',{staticClass:"subtitle mb-1 primary--text"},[_vm._v("Эксперты по оценке:")]),(_vm.item.expertsList.length)?_c('v-card',{staticClass:"mb-1",style:({
              maxHeight: '164px',
              overflowY: 'auto',
              position: 'relative',
            }),attrs:{"elevation":"3"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.item.expertsList),function(item,idx){return _c('v-list-item',{key:idx,staticClass:"brd-b"},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"max-width":"100%"}},[_c('p',{style:({
                        maxWidth: '100%',
                        lineHeight: '20px',
                      })},[_vm._v(" "+_vm._s(item.fullName)+" ")])])])],1)}),1),_c('v-icon',{style:({
                color: '#fd3018 !important',
                position: 'absolute',
                top: '8px',
                right: '10px',
              })},[_vm._v("mdi-bookmark")])],1):_vm._e()],1),_c('v-hover',{style:({
            position: 'absolute',
            top: '0',
            right: '0',
          }),scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('div',[_c('v-icon',{staticClass:"text-center",attrs:{"color":"#767676","small":""}},[_vm._v("mdi-help-circle ")]),_c('div',{staticStyle:{"position":"relative"}},[(hover)?_c('div',{staticClass:"elevation-3 pa-3 white",staticStyle:{"position":"absolute","z-index":"100","overflow-y":"auto","max-height":"200px","width":"275px","right":"0"}},[_vm._v(" Эксперты по оценке проводят ассессмент-центр и составляют отчет по итогам оценки. Красным флажком отмечен главный эксперт по оценке, который отвечает за итоговую проверку и публикацию отчета с результатами оценки ")]):_vm._e()])],1)]}}])})],1),_c('list',{staticClass:"mr-5",staticStyle:{"width":"240px"},attrs:{"cases":{ title: 'Руководители' },"itemTitle":"fullName","data":_vm.item.leadersList,"height":"164px"}}),_c('list',{staticClass:"mr-5",staticStyle:{"width":"240px"},attrs:{"cases":{ title: 'Наблюдатели' },"itemTitle":"fullName","data":_vm.item.observersList,"height":"164px"}}),_c('list',{staticClass:"mr-5",staticStyle:{"width":"240px"},attrs:{"cases":{ title: 'Администратор' },"itemTitle":"fullName","data":[
          ((_vm.item.participantUser.invitedBy) + "<br>" + (_vm.item.createdByEmail) + "<br>" + (_vm.item.createdByPhone)) ],"string-array":""}})],1),_c('div',{staticClass:"d-flex"},[_c('list',{style:({
          width: '350px',
        }),attrs:{"cases":{ title: 'Материалы для подготовки' },"itemTitle":"name","links":"","data":_vm.item.preparationMaterials,"height":"247px"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }