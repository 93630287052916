<template>
  <v-card elevation="0">
    <v-card-text>
      <div class="d-flex mb-1">
        <v-list-item-subtitle style="max-width: 62px; min-width: 62px"
          >Кому:
        </v-list-item-subtitle>
        <p class="text--primary" style="font-weight: 500">
          {{ message.from }}
        </p>
      </div>
      <div v-if="message.copy.length" class="d-flex mb-1">
        <v-list-item-subtitle style="max-width: 62px; min-width: 62px"
          >Копия:
        </v-list-item-subtitle>
        <p class="text--primary" style="font-weight: 500">
          {{ message.copy.filter(Boolean).join("; ") }}
        </p>
      </div>
      <div
        class="py-3 brd-a"
        style="
          max-height: 600px;
          min-height: 200px;
          font-size: 14px;
          border-radius: 6px;
        "
      >
        <p
          class="px-3 mt-1 text--primary"
          style="max-height: 500px; overflow: auto"
          v-html="replacer(message.text || '')"
        ></p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { useLineBreakReplacer } from "@/use/helpers";

export default {
  props: ["session", "message", "date", "time", "isViewMode"],
  setup() {
    const { replacer } = useLineBreakReplacer();

    return {
      replacer,
    };
  },
};
</script>

<style lang="scss" scoped></style>
