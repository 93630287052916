<template>
  <div
    v-if="event.id"
    class="d-flex flex-column flex-grow-1 align-items-stretch py-4"
    style="position: relative; max-height: calc(100vh - 90px)"
  >
    <v-card elevation="0" class="px-4">
      <v-card-text class="pa-0 d-flex justify-space-between">
        <p>{{ selectedDate }}</p>
        <p>
          {{ event.startTime }} -
          {{ event.endTime }}
        </p>
      </v-card-text>
    </v-card>
    <div
      style="font-weight: 500; font-size: 16px; white-space: normal"
      class="text--primary mt-1 px-4 d-flex flex-shrink-1"
      v-text="event.title"
    />
    <div class="pa-4 brd-b">
      <div class="d-flex mb-2" style="font-weight: 500">
        <div class="mr-2" style="margin-top: 3px; width: 16px; height: 16px">
          <img :src="require('@/assets/img/accept.svg')" />
        </div>
        <p>{{ event.from }} (организатор)</p>
      </div>
      <div
        v-for="item in event.copy"
        :key="item"
        class="d-flex mb-2"
        style="font-weight: 500"
      >
        <div class="mr-2" style="margin-top: 3px; width: 16px; height: 16px">
          <img :src="require('@/assets/img/accept.svg')" />
        </div>
        <p>
          {{ item }}
        </p>
      </div>
    </div>
    <p
      class="pa-4 flex-grow-1 text--primary"
      style="overflow: auto"
      v-html="replacer(event.text || '')"
    />
    <div
      v-if="event.documents && event.documents.length"
      class="mt-5 mx-3 brd-a"
      style="border-radius: 6px"
    >
      <v-list>
        <v-list-item
          v-for="(doc, index) in event.documents"
          :key="index"
          :title="doc.name"
          class="my-1"
          style="min-height: 24px"
        >
          <a :href="doc.link" target="_blank">
            <div class="d-flex align-center">
              <div class="mr-1 mb-1" style="width: 15px; height: 15px">
                <img :src="require('@/assets/img/document2.svg')" />
              </div>

              <v-list-item-content class="py-1">
                <p style="font-size: 14px">{{ doc.name }}</p>
              </v-list-item-content>
            </div>
          </a>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import { useLineBreakReplacer } from "@/use/helpers";

export default {
  props: ["event"],
  setup(_) {
    const { replacer } = useLineBreakReplacer();

    const selectedDate = computed(() => {
      let date = new Date(_.event.date).toLocaleString("ru-RU", {
        month: "long",
        day: "2-digit",
        weekday: "long",
      });

      return date[0].toUpperCase() + date.slice(1);
    });

    return {
      replacer,
      selectedDate,
    };
  },
};
</script>
