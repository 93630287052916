<template>
  <v-container
    id="calendarPage"
    :fluid="true"
    v-if="session.id"
    style="height: 100%"
    class="pt-0 px-0"
  >
    <div class="d-flex" style="height: 100%">
      <div class="d-flex flex-column brd-a" style="width: 300px">
        <div
          class="d-flex justify-center"
          style="overflow-x: auto; background: rgba(231, 233, 243, 0.3)"
        >
          <v-date-picker
            v-model="simulationDate"
            :first-day-of-week="1"
            locale="ru-ru"
            :header-date-format="datePickerDate"
            :weekday-format="weekDay"
            color="primary"
            :no-title="true"
          ></v-date-picker>
        </div>
        <div class="flex-grow-1" style="position: relative">
          <v-sheet
            style="
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
            "
          >
            <v-calendar
              id="calendar"
              color="primary"
              type="day"
              interval-minutes="30"
              first-interval="17"
              interval-count="20"
              interval-height="64"
              :start="simulationDate"
              locale="ru-ru"
              :events="events"
              :short-intervals="false"
              event-color="rgba(169, 169, 170, 0.85)"
            >
              <template v-slot:event="{ event, eventParsed }">
                <div
                  :class="[
                    'text-center d-flex px-2 align-center',
                    { 'event--active': event == selectedEvent },
                  ]"
                  style="height: 100%; user-select: none; overflow: hidden"
                  @click="selectEvent(event)"
                  :title="event.title"
                >
                  <p
                    class="flex-grow-1"
                    style="
                      font-weight: 500;
                      line-height: 1rem;
                      max-height: 100%;
                    "
                  >
                    {{ event.title }}
                  </p>
                  <p>{{ eventParsed.start.time }}</p>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
        </div>
      </div>
      <Meeting :event="selectedEvent" />
    </div>
  </v-container>
</template>

<script>
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import Meeting from "./assets/Meeting";

export default {
  props: ["session"],
  setup(_, { root }) {
    onMounted(() => {
      let scrollArea = root.$el.querySelector(".v-calendar-daily__scroll-area");
      let el = root.$el.querySelector(".event--active");
      scrollArea.scrollTop = el && el.offsetParent.offsetTop - 30;
    });

    const selectedEvent = ref(_.session.meetings[0] || {});
    watch(selectedEvent, val => {
      _.session.selectedEvent = val;
    });
    const selectEvent = event => {
      selectedEvent.value = event;
    };

    const simulationDate = ref(_.session.simulationTs.split("T")[0]);

    const events = computed(() => {
      return _.session.meetings.map(el => {
        el.start = `${simulationDate.value} ${el.startTime}`;
        el.end = `${simulationDate.value} ${el.endTime}`;
        return el;
      });
    });

    const datePickerDate = date => {
      return new Date(date)
        .toLocaleString("ru-RU", {
          month: "long",
          year: "numeric",
        })
        .slice(0, -3);
    };

    const weekDay = date => {
      return new Date(date).toLocaleString("ru-RU", { weekday: "short" });
    };

    return {
      selectedEvent,
      selectEvent,
      simulationDate,
      events,
      datePickerDate,
      weekDay,
    };
  },
  components: { Meeting },
};
</script>

<style lang="scss" scoped>
img {
  pointer-events: none !important;
  user-select: none !important;
}
</style>
<style lang="scss">
#calendarPage {
  .event {
    &--active {
      background-color: #4c5ca7 !important;
    }
  }
  .v-picker {
    pointer-events: none !important;
    thead th {
      text-transform: capitalize;
    }
    td {
      padding: 0 !important;
    }
    &.theme--light.v-card,
    &__body {
      background-color: transparent !important;
    }
  }
  .v-date-picker-header {
    &__value {
      button {
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .v-calendar {
    .v-event-timed {
      white-space: normal !important;
    }
  }
  .v-calendar-daily {
    border-top: none;
    border-left: none;
    &__intervals-head,
    &_head-day-label,
    &_head-weekday {
      display: none !important;
    }
    &_head-day {
      border-right: none;
    }
    &__interval-text {
      font-size: 13px;
      top: -11px;
    }
  }
}
</style>
