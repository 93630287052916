<template>
  <div class="pr-3 h-100 d-flex">
    <v-card
      class="d-flex flex-column flex-grow-1"
      color="rgba(231, 233, 243, 0.4)"
      elevation="0"
    >
      <v-app-bar flat>
        <div class="d-flex">
          <Nav
            :tabs="tabs"
            :currentTab="currentTab"
            @tab-selected="setActiveTab"
          />
        </div>
        <div
          v-if="
            tabs.find(el => el.name === currentTab).label === 'Мои заметки' &&
            isTextChanged
          "
        >
          <v-btn icon @click="resetNote">
            <v-icon color="#767676">mdi-close</v-icon>
          </v-btn>
          <v-btn icon @click="updateNote">
            <v-icon color="#767676">mdi-check</v-icon>
          </v-btn>
        </div>
      </v-app-bar>
      <v-textarea
        v-if="tabs.find(el => el.name === currentTab).label === 'Мои заметки'"
        solo
        flat
        :no-resize="true"
        class="pb-1 px-1 d-flex flex-grow-1"
        background-color="transparent"
        label="Введите текст..."
        v-model="editedText"
      />
      <div
        v-else
        class="mb-3 px-5 d-flex flex-grow-1"
        style="max-height: calc(100vh - 186px); overflow-y: auto"
        v-html="replacer(noteToShow || 'Нет заметок')"
      />
    </v-card>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import { useTabs, useLineBreakReplacer } from "@/use/helpers";
import Nav from "@/components/shared/assets/Nav";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const { replacer } = useLineBreakReplacer();

    const role = computed(() => store.getters.getCurrentRole);
    const simulation = computed(() => store.getters.getSimulation);

    const filteredNotes = computed(() => {
      switch (role.value) {
        case "expert": {
          return [
            {
              author: "Мои заметки",
              ...simulation.value.expertNote,
            },
            {
              author: simulation.value.participantFullName,
              text: simulation.value.participantNote,
            },
            ...simulation.value.othersNotes,
          ].map((el, index) => ({
            id: el.id || String(index),
            ...el,
          }));
        }
        case "observer":
        case "executive":
          return [
            {
              author: "Мои заметки",
              ...simulation.value.note,
            },
            {
              author: simulation.value.participantFullName,
              text: simulation.value.participantNote,
            },
          ].map((el, index) => ({
            id: el.id || String(index),
            ...el,
          }));
        case "participant":
          return [
            {
              author: "Мои заметки",
              text: simulation.value.participantNote,
            },
          ].map((el, index) => ({
            id: el.id || String(index),
            ...el,
          }));
      }
    });

    const { currentTab, selectTab } = useTabs(
      filteredNotes.value[0].noteId || filteredNotes.value[0].id
    );

    const editedText = ref(filteredNotes.value[0].text);
    const originalNoteText = ref(filteredNotes.value[0].text);
    const noteToShow = ref(filteredNotes.value[0].text);

    const tabs = computed(() =>
      filteredNotes.value.map(note => ({
        name: note.noteId || note.id,
        label: note.author,
      }))
    );

    const setActiveTab = async tabName => {
      selectTab(tabName);
      noteToShow.value = filteredNotes.value.find(
        n => n.noteId === tabName || n.id === tabName
      ).text;
    };

    const isTextChanged = computed(
      () => editedText.value !== originalNoteText.value
    );

    const resetNote = () => (editedText.value = originalNoteText.value);

    const updateNote = () =>
      store
        .dispatch(
          "setSimulationNote",
          role.value === "participant"
            ? editedText.value
            : {
                noteId:
                  filteredNotes.value[0].noteId || filteredNotes.value[0].id,
                text: editedText.value || "",
              }
        )
        .then(() => (originalNoteText.value = editedText.value));

    return {
      filteredNotes,
      replacer,
      tabs,
      currentTab,
      selectTab,
      setActiveTab,
      editedText,
      noteToShow,
      isTextChanged,
      resetNote,
      updateNote,
    };
  },
  components: { Nav },
};
</script>
