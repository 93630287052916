<template>
  <div
    @click="
      !isViewMode && $emit('toggle-bookmark'),
        !isViewMode && (isImportant = !isImportant)
    "
  >
    <v-icon
      v-if="!isImportant"
      title="Пометить как важное"
      class="bookmark"
      :style="{
        backgroundImage: `url(${require('@/assets/img/bookmark-inactive.svg')})`,
      }"
    >
    </v-icon>

    <v-icon
      v-else
      title="Пометить как обычное"
      class="bookmark"
      :style="{
        backgroundImage: `url(${require('@/assets/img/bookmark.svg')})`,
      }"
    >
    </v-icon>
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: ["email", "isViewMode"],
  setup(_) {
    const isImportant = ref(_.email?.important);

    watch(
      () => _.email,
      val => (isImportant.value = val.important),
      { deep: true }
    );

    return { isImportant };
  },
};
</script>

<style lang="scss">
.bookmark {
  position: absolute;
  top: -4px;
  right: 0;
  width: 13px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  cursor: pointer;
}
</style>
