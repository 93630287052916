<template>
  <v-container>
    <v-row
      justify="end"
      align="center"
      :style="{
        height: '68px',
      }"
    >
      <v-col class="d-flex justify-end">
        <div>
          <span class="filter-btn" @click="switchFilterToggle">
            {{ filterToggle ? "Скрыть фильтры" : "Показать фильтры" }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-container>
        <DataFilter v-if="filterToggle" />
        <infinity-collection @scrolled="getSessions">
          <v-data-table
            :mobile-breakpoint="null"
            fixed-header
            :loading="loading"
            show-expand
            style="width: 100%"
            class="elevation-6"
            item-key="id"
            :headers="headers"
            :items="content"
            :items-per-page="content.length"
            hide-default-footer
            height="450px"
            :expanded.sync="expanded"
            :custom-sort="customSort"
            :sort-by="['assessmentSessionDate']"
            @click:row="expandRow"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <ExpandedRow :headers="headers" :item="item" />
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="['IN_PROGRESS', 'PENDING'].includes(item.status)"
                icon
                class="mr-2"
                @click.stop="selectSession(item.id)"
              >
                <v-icon color="#767676"> mdi-plus-box-outline </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </infinity-collection>
      </v-container>
    </v-row>

    <preloader v-if="loading" class="preloader" />
  </v-container>
</template>

<script>
import { ref, computed, inject } from "@vue/composition-api";

import { useSetFilters, useModalToggle, useToggle } from "@/use/helpers";
import useTableHeaders, { customSort } from "@/use/tableHeaders";

import DataFilter from "@/components/shared/assets/table/DataFilter";
import ExpandedRow from "./ExpandedRow";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const role = computed(() => store.getters.getCurrentRole);

    const { toggle: filterToggle, switchToggle: switchFilterToggle } =
      useToggle();
    const { toggle, switchToggle } = useModalToggle();

    const loading = inject("loading");

    const getSessions = async () => {
      loading.value = true;
      await store.dispatch("fetchSessions", role.value);
      loading.value = false;
    };

    const expanded = ref([]);
    const expandRow = row => {
      const rowIndex = expanded.value.findIndex(el => el.id == row.id);

      if (rowIndex !== -1) {
        expanded.value.splice(rowIndex, 1);
      } else {
        expanded.value.push(row);
      }
    };

    const selectSession = id => {
      loading.value = true;
      store.dispatch("fetchSimulation", id);
    };

    const { expertSessionHeaders } = useTableHeaders(root.$store);
    const headers = computed(() => expertSessionHeaders);
    const content = computed(() => store.getters.getSessions.content);

    return {
      role,
      filterToggle,
      switchFilterToggle,
      toggle,
      switchToggle,
      loading,
      getSessions,
      expanded,
      expandRow,
      selectSession,
      headers,
      content,
      customSort,
      ...useSetFilters(headers, content),
    };
  },
  components: {
    DataFilter,
    ExpandedRow,
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
}
</style>
